.custom-range-input {
  -webkit-appearance: none;
  max-width: 150px;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  margin: 10px 0;
  cursor: pointer;
  position: relative;
}

.custom-range-input:hover {
  opacity: 1;
}

/* Loaded portion indicator */
.custom-range-input::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;

  width: var(--range-loaded, 0%);
  height: 100%;
  background: #595959;
  border-radius: 5px 5px 5px 5px;

  pointer-events: none;
}

/* Current time indicator */
.custom-range-input::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;

  width: var(--range-progress, 0%);
  height: 100%;
  background: black;
  border-radius: 5px 5px 5px 5px;
  pointer-events: none;
  z-index: 1;
}

/* Hide the thumb by default */
.custom-range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
  background: transparent;
  cursor: pointer;
  transition: width 0.2s, height 0.2s;
  z-index: 2;
  position: relative;
}

.custom-range-input::-moz-range-thumb {
  width: 0;
  height: 0;
  background: transparent;
  cursor: pointer;
  border: none;
  transition: width 0.2s, height 0.2s;
  z-index: 2;
  position: relative;
}

/* Show the thumb on hover */
.custom-range-input:hover::-webkit-slider-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000000;
}

.custom-range-input:hover::-moz-range-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000000;
}

.custom-range-input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
