.AudioWorkPlaceTimelineEditTranslation {
  font-family: var(--global-text-font);
  /* color: #252525a6; */
  border: 1px solid #bfbfbf;
  width: 90px;
  height: 40px;
  font-size: 15px;
  border: none;
  resize: none;
  background-color: white;

  border-radius: 5px;
}
.AudioWorkPlaceTimelineEditTranslation:focus {
  outline: none;
  background-color: var(--global-third-color);
  color: var(--global-second-color);
}
.AudioWorkPlaceTimelineEditTranslation:hover {
  outline: none;
  background-color: var(--global-third-color);
  color: var(--global-second-color);
}
