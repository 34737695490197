.headerContainer {
  width: 100%;
  height: 98px;
  background: var(--global-background-color);
}

.logo {
  width: 90px;
  height: 90px;
}

.logoName {
  font-size: 38px;
  font-weight: 900;
  color: var(--global-color);
  font-family: var(--global-headers-font);
}

.icons {
  /* background-color: var(--global-background-color); */
  color: var(--global-color);
}
.textFont {
  color: var(--global-text-font);
}
