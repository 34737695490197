.header {
  font-size: 55px;
  font-weight: 600;
  color: var(--global-color);
  font-family: var(--global-headers-font);
  padding: 20px;
}

.labels {
  color: var(--global-color);
  font-family: var(--global-text-font);
}

.HomeCardDetails {
  background: var(--global-background-color);
  border: 1px solid #ebebeb;
  border-radius: 5px;
}

.SampleInputForm {
  font-family: var(--global-text-font);
  font-size: 20px;
  text-indent: 5px;
  outline: none;
  border-radius: 10px;
  height: 45px;
  border: 2px solid #bfbfbf;
}

.SampleInputForm:hover {
  font-family: var(--global-text-font);
  font-weight: 500;
  background-color: #f5e1a9;
  border: 2px solid #314b5a;
}

.SampleActiveButton {
  border-color: transparent;
  background-color: var(--global-second-color);
  border: 1px solid var(--global-color);
  border-radius: 5px;
  width: 93px;
  height: 29px;
  color: #f5f5f5;
  font-family: var(--global-text-font);
  font-size: 15px;
  cursor: pointer;
}

.SampleActiveButton:hover {
  border-color: transparent;
  background-color: var(--global-color);
  border: 1px solid var(--global-second-color);
  border-radius: 5px;
  width: 93px;
  height: 29px;
  color: var(--global-second-color);
  font-family: var(--global-text-font);
  font-size: 15px;
  cursor: pointer;
}
