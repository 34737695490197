.header {
  font-size: 38px;
  font-weight: 900;
  color: var(--global-second-color);
  font-family: var(--global-headers-font);
}
.EditingAudio {
  width: 100%;
  border-radius: 15px;
  opacity: 1;
}

.OnAudioFiles {
  width: 100%;
  height: 190px;
  background-color: white;
  border: 2px solid #eeeef0;
  border-radius: 10px;
  transition: height 0.5s, background-color 0.5s;
}

.CharacterWorkSpeakerEditInput {
  font-family: var(--global-text-font);
  color: #ffffff;
  /* background-color: green; */
  background-color: var(--global-second-color);
  margin-top: 5px;
  margin-bottom: 5px;
  height: 20px;
  width: 90%;
  font-size: 15px;
  border: none;
  resize: none;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Hide vertical scrollbar */
  white-space: nowrap; /* Display text in a single line */
}

.CharacterWorkSpeakerEditInput::-webkit-scrollbar {
  width: 10px; /* Adjust scrollbar width */
  height: 5px; /* Adjust scrollbar height */
}

.CharacterWorkSpeakerEditInput::-webkit-scrollbar-thumb {
  background-color: #555555; /* Change scrollbar thumb color */
}

.CharacterWorkSpeakerEditInput:focus {
  outline: none;
  background-color: rgb(21, 159, 224);
}

.OffAudioFiles {
  width: 100%;
  height: 50px;
  background-color: white;
  border: 2px solid #eeeef0;
  border-radius: 10px;
  transition: height 0.5s, background-color 0.5s;
}
/* */
.AudioFilesBoxScroller {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 5px;
  max-height: 800px;
  height: 800px;
  overflow-y: auto;
}

.AudioFilesBoxScroller::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.AudioFilesBoxScroller::-webkit-scrollbar-track {
  background-color: none;
}

.AudioFilesBoxScroller::-webkit-scrollbar-thumb {
  background: #717171; /* Thumb color */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.AudioFilesBoxScroller::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

/* */

.AudioTitles {
  background: var(--global-second-color) 0% 0% no-repeat padding-box;
  color: white;
  height: 48px;
}

.AudioTitleFont {
  font: 23px;
  opacity: 1;
  color: var(--global-color);
  font-family: var(--global-headers-font);
}

.AudioWorkPlace {
  width: 100%;
  height: 720px;
  background-color: white;
  border: 2px solid #eeeef0;
  border-radius: 10px;
}

.AudioWorkPlaceButton {
  /* border-color: white; */
  border-color: transparent;
  background-color: var(--global-second-color);
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: 93px;
  height: 29px;
  color: #f5f5f5;
  font: normal normal 600 Quicksand;
  font-size: 10px;
  cursor: pointer;
}

.AudioWorkPlaceInsideTitle {
  color: var(--global-second-color);

  font-family: var(--global-text-font);
  font-size: 15px;
  font-weight: 500;
}

.AudioWorkPlaceBoxes {
  background-color: #ebebeb;
  width: 75%;
  height: 65px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
}

.AudioWorkTextEditInput {
  font-family: var(--global-text-font);
  color: black;
  background-color: #ebebeb;
  width: 95%;
  font-size: 15px;
  border: none;
  resize: none;
  overflow: auto;
}
.AudioWorkTextEditInput:focus {
  outline: none;
}

.AudioWorkSelector {
  background-color: #f1f3f4;
  color: black;
  border: 1px solid #9fa7da;
  border-radius: 8px;
  font-size: 14px;
  height: 36px;
  font-family: var(--global-text-font);
}

.AudioWorkSelector:hover {
  outline: none;
  border: 1px solid #76777b;
  background: var(--global-third-color); /* Thumb color on hover */
}

.AudioWorkNoteEditInput {
  font-family: var(--global-text-font);
  color: black;
  background-color: #f1f3f4;
  width: 85%;
  height: 120%;
  font-size: 14px;
  border: 2px solid #f1f3f4;
  resize: none;
  overflow: auto;
  border-radius: 8px;
}
.AudioWorkNoteEditInput:focus {
  outline: none;
  background: var(--global-third-color);
  border: 2px solid var(--global-second-color);
}

.AudioWorkNoteEditInput::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.AudioWorkNoteEditInput::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.AudioWorkNoteEditInput::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 20px;
}

.AudioWorkNoteEditInput::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.AudioWorkSpeakerEditInput {
  color: var(--global-second-color);
  background-color: #ebebeb;
  font-weight: 900;
  /* width: 95%; */
  height: 20px;
  width: 200px;
  font-size: 16px;
  border: none;
  resize: none;
  overflow: auto;
}
.AudioWorkSpeakerEditInput:focus {
  outline: none;
}

.AudioWorkPlaceTimeline {
  color: #252525a6;

  font-family: var(--global-text-font);
  font-size: 14px;
  font-weight: 650;
}

.AudioWorkPlaceTimelineEditTrans {
  color: #252525a6;

  font-family: var(--global-text-font);
  font-size: 15xp;
  border: none;
  resize: none;
  overflow: auto;
  font-weight: 900;
  background-color: white;
  border-radius: 5px;
  height: 10px;
}

.AudioWorkPlaceTimelineEditTranslation {
  color: #252525a6;

  font-family: var(--global-text-font);
  width: 98px;
  height: 20px;
  font-size: 15px;
  border: none;
  resize: none;

  background-color: white;
  font-weight: 900;
  border-radius: 5px;
}
.AudioWorkPlaceTimelineEditTranslation:focus {
  outline: none;
}

.AudioWorkPlaceTimelineEdit {
  color: #252525a6;

  font-family: var(--global-text-font);
  width: 100%;
  font-size: 15px;
  border: none;
  resize: none;
  overflow: auto;
  font-weight: 900;
  height: 20px;
}
.AudioWorkPlaceTimelineEdit:focus {
  outline: none;
}

.AudioWorkPlaceTimelineEditInput {
  color: #0040ff;

  font-family: var(--global-text-font);
  width: 100%;
  font-size: 15px;
  border: none;
  resize: none;
  overflow: auto;
  font-weight: 900;
  height: 20px;
}
.AudioWorkPlaceTimelineEditInput:focus {
  outline: none;
}

.AudioWorkPlaceChatBoxScroller {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 5px;
  max-height: 800px;
  height: 400px;

  overflow-y: auto;
}

.AudioWorkPlaceChatBoxScroller::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.AudioWorkPlaceChatBoxScroller::-webkit-scrollbar-track {
  background-color: none;
}

.AudioWorkPlaceChatBoxScroller::-webkit-scrollbar-thumb {
  background: #717171; /* Thumb color */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.AudioWorkPlaceChatBoxScroller::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

.AudioOutPutHorizontal {
  width: 100%;
  height: 500px;
  border-radius: 15px;
  opacity: 1;
  background-color: none;
}

.AudioOutPut {
  width: 100%;
  height: 725px;
  border-radius: 15px;
  opacity: 1;
  background-color: none;
}

.AudioOutPutVideo {
  height: 618px;
  width: 100%;
  background: #252525 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
}

.AudioOutPutVideoHorizontal {
  height: 380px;
  width: 100%;
  background: #252525 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
}

.AudioOutPutController {
  height: 100px;
  width: 100%;
  border: 1px solid #707070;
  background-color: white;

  border-bottom-left-radius: 10px; /* Adjust the radius as needed */
  border-bottom-right-radius: 10px;
}

/* .AudioOutPutShownTime {
  width: 125px;
  height: 41px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
} */
.AudioOutPutShowTimeOptionBox {
  width: 125px;
  height: 41px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  font-size: 15px;
}

.AudioOutPutShowTimeOptionBox:hover {
  border: 2px solid var(--global-second-color);
}

.option {
  text-align: center;
  font-size: 15px;
}

.AudioOutPutShowTimeOptionBox:focus {
  outline: none;
}

.AudioOutPutShowTimeOptionBox:focus {
  border: 2px solid var(--global-second-color);
}

/* 
AudioWaveForm ---------------------------------------------------------------------------
*/

.AudiWaveForm {
  /* height: 1px; */
  /* margin-bottom: 10px; */
}

.AudiTimeLine {
  background-color: #eeeef0;
  /* margin-bottom: 100px; */
}

.AudioTimeTextBox {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 5px;
  /* max-height: 80px;
  width: 200px; */
  overflow-y: auto;
}

.AudioTimeTextBox::-webkit-scrollbar {
  width: 3px;
  height: 9px;
}

.AudioTimeTextBox::-webkit-scrollbar-track {
  background-color: none;
}

.AudioTimeTextBox::-webkit-scrollbar-thumb {
  /* Thumb color */
  border-radius: 100px; /* Rounded corners for the thumb */
  background: #555;
}

.AudioTimeTextBox::-webkit-scrollbar-thumb:hover {
  background: var(--global-second-color); /* Thumb color on hover */
}

.AudioTimeTextBoxItems {
  height: 45px;
  width: 100px;
  background-color: #93a7f8;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid var(--global-second-color);
  color: white;
}

/* 
End
*/

/* //-------------------------------------------------// */

/* .VoiceRecording {
  width: 100px;
} */

/* //-------------------------------------------------// */

/* DeleteConfirmationModal Component 
-------------------------------------------------// */

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.popup-inner {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  width: 13%;
  height: 14.5%;
}
/* //-------------------------------------------------// */
.AudioOutPutController {
  height: 80px;
  width: 100%;
  border: 1px solid #707070;
  background-color: var(--global-second-color);
  color: white;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.AudioOutPutShowTimeOptionBox {
  /* height: 32px; */
  width: 80px;
  text-align: center;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  background-color: white;
  color: black;
  font-size: 14px;
}

.AudioWorkPlaceTimelineEditTranslation {
  width: 90px;
  height: 32px;
  background-color: white;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  padding: 4px;
}
