.header {
  font-size: 38px;
  font-weight: 900;
  color: var(--global-second-color);
  font-family: var(--global-headers-font);
}

.EditingAudio {
  width: 100%;
  border-radius: 15px;
  opacity: 1;
}

.OnAudioFiles {
  width: 100%;
  height: 190px;
  background-color: white;
  border: 2px solid #eeeef0;
  border-radius: 10px;
  transition: height 0.5s, background-color 0.5s;
}

.CharacterWorkSpeakerEditInput {
  color: #ffffff;
  background-color: #ebebeb;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 20px;
  width: 100%;
  font-size: 15px;
  font-family: var(--global-text-font);
  color: black;
  border: none;
  resize: none;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: hidden; /* Hide vertical scrollbar */
  white-space: nowrap; /* Display text in a single line */
  border-radius: 5px;
  border: 1px solid #bfbfbf;
}

.CharacterWorkSpeakerEditInput::-webkit-scrollbar {
  width: 10px; /* Adjust scrollbar width */
  height: 5px; /* Adjust scrollbar height */
}

.CharacterWorkSpeakerEditInput::-webkit-scrollbar-thumb {
  background-color: #555555; /* Change scrollbar thumb color */
}

.CharacterWorkSpeakerEditInput:focus {
  outline: none;
  background-color: var(--global-third-color);
  color: var(--global-second-color);
}

.OffAudioFiles {
  width: 100%;
  height: 50px;
  background-color: white;
  border: 2px solid #eeeef0;
  border-radius: 10px;
  transition: height 0.5s, background-color 0.5s;
}
/* */
.AudioFilesBoxScroller {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 5px;
  max-height: 800px;
  height: 800px;
  overflow-y: auto;
}

.AudioFilesBoxScroller::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.AudioFilesBoxScroller::-webkit-scrollbar-track {
  background-color: none;
}

.AudioFilesBoxScroller::-webkit-scrollbar-thumb {
  background: #717171; /* Thumb color */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.AudioFilesBoxScroller::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

/* */

.AudioTitles {
  background: var(--global-second-color) 0% 0% no-repeat padding-box;
  color: white;
  height: 48px;
}

.AudioTitleFont {
  font-size: 19px;
  font-weight: 500;
  font-family: var(--global-headers-font);
  color: var(--global-color);
}

.AudioWorkPlace {
  width: 100%;
  height: 542px;
  background-color: white;
  border: 2px solid #eeeef0;
  border-radius: 10px;
}

.AudioWorkPlaceButton {
  /* border-color: white; */
  border-color: transparent;
  background-color: var(--global-second-color);
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: 93px;
  height: 29px;
  color: #f5f5f5;
  font: normal normal 600 Quicksand;
  font-size: 10px;
  font-family: var(--global-text-font);

  cursor: pointer;
}

.AudioWorkPlaceInsideTitle {
  color: var(--global-second-color);

  font-size: 15px;
  font-family: var(--global-text-font);

  font-weight: 500;
}

.AudioWorkPlaceBoxes {
  background-color: #ebebeb;
  width: 75%;
  height: 92px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
}

.AudioWorkTextEditInput {
  color: black;
  background-color: #f1f3f4;
  border-radius: 8px;
  width: 95%;
  height: 55px;
  font-size: 16px;
  font-family: var(--global-text-font);

  border: none;
  resize: none;
  overflow: auto;
}
.AudioWorkTextEditInput:focus {
  outline: none;
  background: #ffffee;
  border: 2px solid var(--global-second-color);
}
.AudioWorkTextEditInput::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.AudioWorkTextEditInput::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.AudioWorkTextEditInput::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 20px;
}

.AudioWorkTextEditInput::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.AudioWorkSelector {
  background-color: #f1f3f4;
  color: black;
  border: 2px solid #f1f3f4;
  border-radius: 8px;
  font-size: 14px;
  font-family: var(--global-text-font);

  height: 30px;
}

.AudioWorkNoteEditInput {
  color: black;
  background-color: #f1f3f4;
  width: 85%;
  height: 120%;
  font-size: 12px;
  font-family: var(--global-text-font);

  border: 2px solid #f1f3f4;
  resize: none;
  overflow: auto;
  border-radius: 8px;
}
.AudioWorkNoteEditInput:focus {
  outline: none;
  border: 2px solid var(--global-second-color);
}

.AudioWorkSpeakerEditInput {
  color: var(--global-second-color);
  background-color: #ebebeb;
  /* font-weight: 900; */
  /* width: 95%; */
  height: 20px;
  width: 200px;
  font-size: 16px;
  font-family: var(--global-text-font);
  border-radius: 5px;
  border: 1px solid #bfbfbf;
  border: none;
  resize: none;
  overflow: auto;
}
.AudioWorkSpeakerEditInput:focus {
  outline: none;
  background-color: var(--global-third-color);
}

.AudioWorkPlaceTimeline {
  color: #252525a6;
  /* */
  font-size: 14px;
  font-family: var(--global-text-font);

  font-weight: 650;
  /* background-color: ; */
}

.AudioWorkPlaceTimelineEdit {
  color: #252525a6;
  border: 1px solid #bfbfbf; /* Light grey outline */
  width: 100px;
  height: 20px;
  font-size: 15px;
  resize: none;
  overflow: auto;
  font-weight: 500;
  border-radius: 5px;
}

.AudioWorkPlaceTimelineEdit:focus {
  outline: none;
  background: var(--global-third-color);
  border: 1px solid var(--global-second-color);
  border-radius: 5px;
  color: black;
}

.AudioWorkPlaceTimelineEditInput {
  width: 75px;
  height: 25px;
  color: blue;
  font-size: 15px;
  border: none;
  resize: none;
  overflow: auto;
  font-weight: 900;
  border-radius: 5px;

  /* background: #000; */
}
.AudioWorkPlaceTimelineEditInput:focus {
  outline: none;
  background: #ffffee;
  border: 2px solid var(--global-second-color);
  border-radius: 5px;
}

.AudioWorkPlaceChatBoxScroller {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 5px;
  max-height: 800px;
  height: 440px;

  overflow-y: auto;
}

.AudioWorkPlaceChatBoxScroller::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.AudioWorkPlaceChatBoxScroller::-webkit-scrollbar-track {
  background-color: none;
}

.AudioWorkPlaceChatBoxScroller::-webkit-scrollbar-thumb {
  background: #717171; /* Thumb color */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.AudioWorkPlaceChatBoxScroller::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

.AudioOutPutVideo {
  height: 549px;

  background: black;
  border: 1px solid #707070;

  /* border-radius: 10px 10px 0px 0px; */
}

.AudioOutPutController {
  height: 80px;
  width: 100%;
  border: 1px solid #707070;
  background-color: var(--global-second-color);
  color: white;

  border-bottom-left-radius: 10px; /* Adjust the radius as needed */
  border-bottom-right-radius: 10px;
}

/* .AudioOutPutShownTime {
  width: 125px;
  height: 41px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
} */

.AudioOutPutShowTimeOptionBox {
  width: 100%;
  height: 41px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  font-size: 15px;
  font-family: var(--global-text-font);
  background-color: white;
  color: black;
}

.AudioOutPutShowTimeOptionBox:hover {
  border: 2px solid var(--global-second-color);
  background: var(--global-third-color);
}

.option {
  text-align: center;
  font-size: 15px;
  font-family: var(--global-text-font);
}

.AudioOutPutShowTimeOptionBox:focus {
  border: 2px solid var(--global-second-color);
  outline: none;
}

/* 
AudioWaveForm ---------------------------------------------------------------------------
*/

.AudiWaveForm {
  /* height: 1px; */
  /* margin-bottom: 10px; */
}

.AudiTimeLine {
  background-color: #eeeef0;
  /* margin-bottom: 100px; */
}

.AudioTimeTextBox {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 5px;
  /* max-height: 80px;
  width: 200px; */
  overflow-y: auto;
}

.AudioTimeTextBox::-webkit-scrollbar {
  width: 3px;
  height: 9px;
}

.AudioTimeTextBox::-webkit-scrollbar-track {
  background-color: none;
}

.AudioTimeTextBox::-webkit-scrollbar-thumb {
  /* Thumb color */
  border-radius: 100px; /* Rounded corners for the thumb */
  background: #555;
}

.AudioTimeTextBox::-webkit-scrollbar-thumb:hover {
  background: var(--global-second-color); /* Thumb color on hover */
}

.AudioTimeTextBoxItems {
  height: 45px;
  width: 100px;
  background-color: #93a7f8;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid var(--global-second-color);
  color: white;
  font-family: var(--global-text-font);
}

.controlLabel {
  color: white;
  font-family: var(--global-text-font);
  font-size: 15px;
  margin-right: 8px;
  white-space: nowrap;
}
