.EditingAudio {
  width: 100%;
  border-radius: 15px;
  opacity: 1;
  font-family: var(--global-text-font);
}

.OnAudioFiles {
  width: 100%;
  height: 190px;
  background-color: white;
  border: 2px solid #eeeef0;
  border-radius: 10px;
  transition: height 0.5s, background-color 0.5s;
}

.CharacterWorkSpeakerEditInput {
  font-family: var(--global-text-font);
  color: #ffffff;
  /* background-color: green; */
  background-color: #5570dc;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 20px;
  width: 90%;
  font-size: 15px;
  border: none;
  resize: none;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Hide vertical scrollbar */
  white-space: nowrap; /* Display text in a single line */
}

.CharacterWorkSpeakerEditInput::-webkit-scrollbar {
  width: 10px; /* Adjust scrollbar width */
  height: 5px; /* Adjust scrollbar height */
}

.CharacterWorkSpeakerEditInput::-webkit-scrollbar-thumb {
  background-color: #555555; /* Change scrollbar thumb color */
}

.CharacterWorkSpeakerEditInput:focus {
  outline: none;
  background-color: rgb(21, 159, 224);
}

.OffAudioFiles {
  width: 100%;
  height: 50px;
  background-color: white;
  border: 2px solid #eeeef0;
  border-radius: 10px;
  transition: height 0.5s, background-color 0.5s;
}
/* */
.AudioFilesBoxScroller {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 5px;
  max-height: 800px;
  height: 800px;
  overflow-y: auto;
}

.AudioFilesBoxScroller::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.AudioFilesBoxScroller::-webkit-scrollbar-track {
  background-color: none;
}

.AudioFilesBoxScroller::-webkit-scrollbar-thumb {
  background: #717171; /* Thumb color */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.AudioFilesBoxScroller::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

/* */

.AudioTitles {
  background: #5570dc 0% 0% no-repeat padding-box;
  color: white;
  height: 48px;
}

.AudioTitleFont {
  font: 19px Roboto;
  opacity: 1;
}

.AudioWorkPlace {
  width: 100%;
  height: 520px;
  background-color: white;
  border: 2px solid #eeeef0;
  border-radius: 10px;
}

.AudioWorkPlaceButton {
  /* border-color: white; */
  border-color: transparent;
  background-color: #5570dc;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: 93px;
  height: 29px;
  color: #f5f5f5;
  font: normal normal 600 Quicksand;
  font-size: 10px;
  cursor: pointer;
}

.AudioWorkPlaceInsideTitle {
  color: #5570dc;

  font-family: var(--global-text-font);
  font-size: 15px;
  font-weight: 500;
}

.AudioWorkPlaceBoxes {
  background-color: #ebebeb;
  width: 75%;
  height: 92px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
}

.AudioWorkTextEditInput {
  font-family: var(--global-text-font);
  color: black;
  background-color: #f1f3f4;
  width: 98%;
  height: 40px;
  font-size: 16px;
  border: none;
  resize: none;
  overflow: auto;
  /* background: lightblue; */
  /* border: 1px solid grey;   */
  border-radius: 7px;
}
.AudioWorkTextEditInput:focus {
  outline: none;
  background: var(--global-third-color);
  border: 2px solid var(--global-second-color);
}
.AudioWorkTextEditInput::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.AudioWorkTextEditInput::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.AudioWorkTextEditInput::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 20px;
}

.AudioWorkTextEditInput::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.AudioWorkSelector {
  background-color: #f1f3f4;
  color: black;
  border: 1 px solid #bfbfbf;
  border-radius: 8px;
  border-radius: 8px;
  font-size: 14px;
  height: 30px;
  font-family: var(--global-text-font);
  width: 140px;
  min-width: 140px;
  max-width: 140px;
}

.AudioWorkSelector:hover {
  background-color: var(--global-third-color);
  color: rgb(0, 0, 0);
  border: 1px solid var(--global-second-color);
  font-family: var(--global-text-font);
}
.AudioWorkSelector:focus {
  background-color: var(--global-third-color);
  color: rgb(0, 0, 0);
  border: 1px solid var(--global-second-color);
  font-family: var(--global-text-font);
}

.AudioWorkSenetenceSelector {
  background-color: #f1f3f4;
  color: black;
  border: 1 px solid #bfbfbf;
  border-radius: 8px;
  font-size: 14px;
  height: 19px;
  width: 20px;
}
.AudioWorkSenetenceSelector:hover {
  background-color: var(--global-third-color);
  color: white;
  border: 1px solid var(--global-second-color);
}
.AudioWorkSenetenceSelector:focus {
  background-color: var(--global-third-color);
  color: white;
  border: 1px solid var(--global-second-color);
}

.AudioVolumeSelector {
  background-color: #f1f3f4;
  color: black;
  border: 1 px solid #bfbfbf;
  border-radius: 8px;
  font-size: 14px;
  height: 19px;
  width: 20px;
}
.AudioVolumeSelector:hover {
  background-color: var(--global-third-color);
  color: rgb(0, 0, 0);
  border: 1px solid var(--global-second-color);
}
.AudioVolumeSelector:focus {
  background-color: var(--global-third-color);
  color: rgb(0, 0, 0);
  border: 1px solid var(--global-second-color);
}

.AudioWorkNoteEditInput {
  font-family: var(--global-text-font);
  color: black;
  background-color: #f1f3f4;
  width: 85%;
  height: 120%;
  font-size: 14px;
  border: 2px solid #f1f3f4;
  resize: none;
  overflow: auto;
  border-radius: 8px;
}
.AudioWorkNoteEditInput:focus {
  outline: none;
  background: var(--global-third-color);
  border: 2px solid var(--global-second-color);
}

.AudioWorkNoteEditInput::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.AudioWorkNoteEditInput::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.AudioWorkNoteEditInput::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 20px;
}

.AudioWorkNoteEditInput::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.AudioWorkSpeakerEditInput {
  color: #5570dc;
  background-color: #ebebeb;
  font-weight: 900;
  /* width: 95%; */
  height: 20px;
  width: 200px;
  font-size: 16px;
  border: none;
  resize: none;
  overflow: auto;
}
.AudioWorkSpeakerEditInput:focus {
  outline: none;
}

.AudioWorkPlaceTimeline {
  color: #252525a6;

  font-size: 14px;
  font-weight: 650;
}

.AudioWorkPlaceTimelineEditTrans {
  color: #252525a6;

  font-size: 15xp;
  border: none;
  resize: none;
  overflow: auto;
  font-weight: 900;
  background-color: white;
  border-radius: 5px;
}

.AudioWorkPlaceTimelineEditTranslation {
  font-family: var(--global-text-font);
  /* color: #252525a6; */
  border: 1px solid #bfbfbf;
  width: 90px;
  height: 20px;
  font-size: 15px;
  border: none;
  resize: none;
  background-color: white;

  border-radius: 5px;
}
.AudioWorkPlaceTimelineEditTranslation:focus {
  outline: none;
  background-color: var(--global-third-color);
  color: var(--global-second-color);
}
.AudioWorkPlaceTimelineEditTranslation:hover {
  outline: none;
  background-color: var(--global-third-color);
  color: var(--global-second-color);
}

.AudioWorkPlaceTimelineEdit {
  color: #252525a6;

  width: 100%;
  font-size: 15px;
  border: none;
  resize: none;
  overflow: auto;
  font-weight: 900;
}
.AudioWorkPlaceTimelineEdit:focus {
  outline: none;
}

.AudioWorkPlaceTimelineEditInput {
  width: 100%;
  color: blue;
  font-size: 15px;
  border: none;
  resize: none;
  overflow: auto;
  font-weight: 900;
}
.AudioWorkPlaceTimelineEditInput:focus {
  outline: none;
}

.AudioWorkPlaceChatBoxScroller {
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  /* grid-gap: 5px; */
  /* max-height: 800px; */
  /* height: 410px; */
  gap: 20px;
  /* overflow-y: auto; */
}

.AudioOutPutHorizontal {
  height: 500px;
  border-radius: 15px;
  opacity: 1;
  position: fixed;
  top: 100px;
  padding: 0px 0px;
  bottom: 2px;
  z-index: 100;
  /* transition: padding 0.2s ease; */
}

.AudioOutPut {
  width: 100%;
  height: 725px;
  border-radius: 15px;
  opacity: 1;
  background-color: none;
}

.AudioOutPutVideo {
  height: 618px;
  width: 100%;
  background: #252525 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
}

.AudioOutPutVideoHorizontal {
  height: 380px;
  width: 100%;
  background: #252525 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
}

.AudioOutPutController {
  height: 100px;
  width: 100%;
  border: 1px solid #707070;
  background-color: var(--global-second-color);
  color: white;

  border-bottom-left-radius: 10px; /* Adjust the radius as needed */
  border-bottom-right-radius: 10px;
}

/* .AudioOutPutShownTime {
  width: 125px;
  height: 41px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
} */

.VideoPlaceTimelineEditTranslation {
  font-family: var(--global-text-font);
  /* color: #252525a6; */
  border: 1px solid #bfbfbf;
  width: 125px;
  height: 41px;
  font-size: 15px;
  border: none;
  resize: none;
  background-color: white;

  border-radius: 5px;
  line-height: 41px; /* Same as the height of the textarea */
  text-align: center;
}
.VideoPlaceTimelineEditTranslation:focus {
  outline: none;
  background-color: var(--global-third-color);
  color: var(--global-second-color);
}
.VideoPlaceTimelineEditTranslation:hover {
  outline: none;
  background-color: var(--global-third-color);
  color: var(--global-second-color);
}

.AudioOutPutShowTimeOptionBox {
  width: 125px;
  height: 41px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  font-size: 15px;

  font-family: var(--global-text-font);
}

.AudioOutPutShowTimeOptionBox:hover {
  border: 2px solid var(--global-second-color);
  background: var(--global-third-color);
}

.option {
  text-align: center;
  font-size: 15px;
  font-family: var(--global-text-font);
}

.AudioOutPutShowTimeOptionBox:focus {
  /* background: var(--global-third-color); */
  outline: none;
}

.AudioOutPutShowTimeOptionBox:focus {
  border: 2px solid var(--global-second-color);
}
