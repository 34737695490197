.footer {
  width: 100%;
  height: 70px; /* Footer height */
  background: var(--global-background-color);
  color: var(--global-color);
  font-family: var(--global-text-font);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 500px;
}
