body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(255, 255, 255); */
  background: linear-gradient(80deg, #f4fbf8, #eef8d4, #9cddc0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --global-color: #cef15d;
  --global-second-color: #314b5a;
  --global-third-color: #f5e1a9;

  --global-background-color: linear-gradient(
    45deg,
    #44687c,
    #163246,
    #254459,
    #44687c
  );
  --global-headers-font: "Concert One", sans-serif;
  --global-text-font: "Livvic", sans-serif;
}
