.labels {
  color: var(--global-second-color);
  font-family: var(--global-text-font);
  font-size: 25px;
  font-weight: 600;
}

.icons {
  color: var(--global-color);
  background: var(--global-second-color);
}

.header {
  font-size: 38px;
  font-weight: 900;
  color: var(--global-second-color);
  font-family: var(--global-headers-font);
}

.midHeader {
  font-size: 20px;
  font-weight: 600;
  color: var(--global-second-color);
  font-family: var(--global-headers-font);
  border-bottom: 1px solid #ebebeb;
}

.bigIcons {
  color: var(--global-color);
  background: var(--global-second-color);
}

.bigIcons:hover {
  color: var(--global-second-color);
  background: var(--global-color);
}

.selector {
  background-color: #f1f3f4;
  color: black;
  border: 1 px solid #bfbfbf;
  border-radius: 8px;
  border-radius: 8px;
  font-size: 14px;
  height: 30px;
  font-family: var(--global-text-font);
}

.selector:hover {
  background-color: var(--global-third-color);
  color: rgb(0, 0, 0);
  border: 1px solid var(--global-second-color);
  font-family: var(--global-text-font);
}
.selector:focus {
  font-family: var(--global-text-font);

  background-color: var(--global-third-color);
  color: rgb(0, 0, 0);
  border: 1px solid var(--global-second-color);
}

.input {
  font-family: var(--global-text-font);
  color: black;
  background-color: #f1f3f4;
  width: 70%;
  height: 100%;
  font-size: 12px;
  border: 1px solid #bfbfbf;
  resize: none;
  overflow: auto;
  border-radius: 8px;
}
.input:focus {
  outline: none;
  background: var(--global-third-color);
  border: 1px solid var(--global-second-color);
}

.input::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.input::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.input::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 20px;
}

.file-upload-button {
  color: var(--global-color);
  background: var(--global-second-color);
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  transition: background-color 0.3s ease;
  border: 1px solid #bfbfbf;
}

.file-upload-button:hover {
  color: var(--global-second-color);
  background: var(--global-color);
  border: 1px solid #bfbfbf;
}
